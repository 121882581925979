import { createMemo } from 'solid-js';
import BgSplashBanner from '~/assets/backgrounds/tempSplashBanner.png';
import { LET_US_GUIDE_YOUR_HOME, BANNER_TITLE, HOME } from '~/assets/strings';
import usePresenter from '~/framework/hooks/usePresenter';
import { useLocalization } from '~/hooks/useLocalization';
import { useTitle } from '~/hooks/useTitle';
import { PropertyForYou } from '~/pages/home/PropertyForYou';
import { CompanyStylePresenter } from '~/presenters/CompanyStylePresenter';

export default function Index() {
  const { model: companyStyling } = usePresenter(CompanyStylePresenter);

  const { t } = useLocalization();
  useTitle({ title: t(HOME) });

  const cover = createMemo(() => {
    if (companyStyling()?.isCompanyInfoLoaded) {
      return companyStyling()?.cover || BgSplashBanner;
    }
    return '';
  });

  return (
    <div class="w-full">
      <div
        class="relative flex h-[440px] w-full justify-center bg-cover bg-center bg-no-repeat sm:h-modalMd md:h-[600px]"
        style={{ 'background-image': `url(${cover()})` }}>
        <div class="absolute inset-0 bg-black/30" />
        <div class="relative mx-auto flex w-full max-w-container flex-col items-center px-4 pt-[100px] sm:pt-[120px] md:pt-[135px]">
          <div class="text-center text-base text-white sm:text-lg">{t(LET_US_GUIDE_YOUR_HOME)}</div>
          <div
            class="mt-2 max-w-[280px] text-center text-3xl font-semibold text-white sm:max-w-[500px]
                      sm:text-4xl sm:leading-normal md:max-w-[700px]
                      md:text-5xl md:leading-normal lg:max-w-[900px]
                      lg:text-[70px] lg:leading-normal">
            {t(BANNER_TITLE)}
          </div>
        </div>
      </div>

      <PropertyForYou />
    </div>
  );
}
